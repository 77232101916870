hr {
  margin: 90px 0;
}

.signup-header {
  font-weight: 700;
  font-size: 30px;
}

.signup-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.privacy-checkbox span {
  font-size: 15px !important;
}

.submit-btn {
  margin-top: 5rem;
  height: 54px;
  width: 168px;
  border-radius: 27px;
  border: 1px solid black;
  margin-left: auto;
  transition: 0.2s;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 576px) {
  .privacy-checkbox span {
    font-size: 12px !important;
  }
  hr {
    margin: 20px 0;
  }
}
