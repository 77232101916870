.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.sub-title {
  font-size: 16px;
  color: #abaaba;
  margin-top: 4px;
}

.sensor-model {
  margin-right: 40px;
}

table,
td,
th {
  border-collapse: collapse;
  color: #333;
}

.start {
  border: 1px solid #d7d7d7;
  border-left: none;
  height: 35px;
  text-align: center;
  padding: 0px 26px;
}

.end {
  border: 1px solid #d7d7d7;
  border-right: none;
  height: 35px;
  padding: 0px 26px;
}

.radio-btn.active {
  color: #fff;
  font-weight: bold;
  background-color: #352d59;
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid #352d59;
}

.radio-btn.active:hover {
  background-color: #43396f;
}

.radio-btn-group.disabled {
  font-weight: normal;
  color: #c1c1c1;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin-right: 5px;
}

.radio-btn-group.active {
  color: #fff;
  font-weight: bold;
  background-color: #352d59;
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid #352d59;
}

.radio-btn-group.active:hover {
  background-color: #43396f;
}

.radio-btn.disabled {
  font-weight: bold;
  color: #352d59;
  border: 1px solid #352d59;
  border-radius: 5px;
  margin-right: 5px;
}

.location-input {
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: 1px solid #d7d7d7;
  width: 100%;
  padding: 0 15px;
  font-size: 16px;
}

.location-input:focus-visible {
  outline: 1px solid #352d59;
}

.sensor-input {
  background-color: #f8f8f8;
  height: 160px;
  padding: 0 50px;
}
