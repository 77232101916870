.side-bar {
  width: 70px;
  height: 100%;
  background-color: #160a4c;
  position: fixed;
  top: 0;
  z-index: 21;
}

.side-link {
  color: #b8b8b8;
  flex-direction: column;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: transparent;
  padding: 0;
}

.side-link-neutral {
  color: #b8b8b8;
  display: flex;
  height: 30px;
  align-items: center;
  font-size: 14px;
  background-color: transparent;
  flex-direction: row;
  height: 30px;
  justify-content: flex-start;
  padding: 0 20px;
}

.side-link.active {
  color: #50cdd0;
  flex-direction: column;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #21205c;
  font-weight: normal;
  padding: 0;
}

@media only screen and (max-width: 900px) {
  .side-bar {
    height: 68px;
  }
  .side-link {
    flex-direction: row;
    height: 30px;
    justify-content: flex-start;
    padding: 0 20px;
  }

  .side-link.active {
    flex-direction: row;
    height: 30px;
    justify-content: flex-start;
    background-color: transparent;
    padding: 0 20px;
    font-weight: bold;
  }
}
