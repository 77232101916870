.find-id-title {
  font-size: 30px;
}

.find-id-lable {
  font-size: 15px;
}

.find-password-radio-wrapper span {
  font-size: 15px;
  padding: 0.1rem 0.2rem;
}
/* 
  .input-name {
    font-weight: bold;
    font-size: 15px;
  } */

.input-group .input-label {
  width: 150px;
  font-weight: 700;
  font-size: 18px;
}

.input-group .org-name {
  width: 300px;
}

.input-group button {
  border-radius: 30px;
  /* border: 1px solid black; */
  height: 45px;
  margin-left: 1rem;
  padding: 0 3rem;
  font-weight: normal !important;
}

.timer {
  /* height: 60px;
    display: flex;
    margin-left: 2rem;
    align-items: center; */
  color: #c4c4c4;
}

.email-select {
  position: relative;
  height: 40px;
  margin-right: 0.4rem;
  border-color: #c4c4c4 !important;
  border-radius: 5px;
}

.email-inputs {
  display: flex;
  flex-direction: column;
  margin-right: 0.4rem !important;
}

.email-sign {
  margin: 0 0.1rem;
}
.email-helptext {
  font-size: 12px;
  margin-top: 10px;
  color: #a4a4a4;
}
