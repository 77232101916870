.img-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.img-wrapper:hover .edit-btn {
  display: block !important;
}

.img-wrapper.hover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
  transition: 0.3s;
}

.img-wrapper:hover.img-wrapper::before {
  background-color: rgba(0, 0, 0, 0.1);
}

.edit-btn {
  display: none !important;
  position: absolute !important;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.place-img {
  background-size: contain;
}
