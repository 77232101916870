.link {
  font-size: 16px;
  color: #868298;
}

.link.active {
  font-weight: bold;
  color: #50cdd0;
}
.input {
  height: 45px;
  flex: 1;
  border: none;
  border-radius: 4px;
  outline: 1px solid #dbdbdb;
  padding: 0 16px;
  font-size: 16px;
}

.input.valid {
  outline: 1px solid #5cffb5;
}
.input.invalid {
  outline: 1px solid #ff7272;
}

/* .input:focus-visible {
  outline: 2px solid #50cdd0;
} */

.input[type="password"] .input.secondary {
  background-color: #f9f9f9;
}

.input.phone-number {
  flex: none;
  width: 180px;
}

.input-label {
  font-size: 16px;
  color: #333;
}

.dash {
  margin: 0 3px;
}

.submit-btn {
  width: 180px;
  height: 38px;
  background-color: #50cdd0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

li {
  color: #aaaaaa;
}

li::marker {
  padding-left: 0;
}
