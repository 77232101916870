.signin-input {
  width: -webkit-fill-available;
  padding: 0 22px;
  height: 55px;
  border-radius: 5px;
  font-size: 22px;
  margin-top: 10px;
  border-width: 0;
}

.field-width {
  width: 440px;
}

.signin-input:focus {
  outline: none;
}

@media screen and (max-width: 576px) {
  .field-width {
    width: 340px;
  }
}
