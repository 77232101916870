/*not found*/
.notfound {
  display: flex;
  flex-direction: column;
  /* background: #f5f5f5; */
  height: 100vh;
}
.notfound h1 {
  display: block;
  position: relative;
  font-size: 200px;
  font-weight: 800;
  text-align: center;
  color: #dceae2;
}
.notfound h1 span {
  display: block;
  width: 70px;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notfound p {
  margin-top: -40px;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  color: #282828;
}
.notfound small {
  display: block;
  margin: 40px auto;
  font-size: 18px;
  line-height: 1.6;
  text-align: center;
  color: #282828;
}
.notfound a {
  display: block;
  margin: 20px auto;
  width: 200px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  border: 1px solid #00934b;
  font-size: 18px;
  font-weight: 500;
  color: #00934b;
  text-align: center;
  transition: all 0.3s ease-in;
}
.notfound a:hover {
  background: #00934b;
  color: #fff;
}
