body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

.input-underline {
  border: none;
  /* padding: 0 0 0 0.25ch; */
  width: 648px;
  background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 36px, transparent 0, transparent 54px) 0 100%/648px
    2px no-repeat;
  font-size: 36px;
  letter-spacing: 1ch;
}
