.more-detail-box {
  margin-top: 20px;
  padding-bottom: 100px;
  padding-right: 1px;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: block;
}

.sub-title {
  font-size: 12px;
  font-weight: normal;
  color: #abaaba;
  display: block;
}

/* .react-datepicker-wrapper .react-datepicker__input-container {
  width: 135px;
  height: 38px;
  border-radius: 3px;
  border: 1px solid #7d88b0;
  display: flex;
  justify-content: flex-start;
} */

.table-cell {
  color: #50cdd0;
}

.gate-select:focus-visible {
  outline: none;
}
