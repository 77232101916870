.social-btn {
  font-size: 18px !important;
}

/* KAKAO BUTTON  */
.kakao-btn {
  background-color: #fae100 !important;
  height: 50px;
  margin-top: 10px !important;
}

/* NAVER BUTTON  */

.naver-btn {
  background-color: #06bd34 !important;
  height: 50px;
}
